import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  useMediaQuery,
  IconButton
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DataOverview from "../components/StyledComponents/DataOverview";
import AdminDataGrid from "../components/StyledComponents/AdminDataGrid";
import { fetchVetDataApi, fetchPatientDataApi } from "../api/AdminAccountApi";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "capitalize",
  backgroundColor: "#FFFFFF",
  color: "gray",
  border: "1px solid #ccc",
  padding: theme.spacing(1, 2),
  fontSize: "1rem",
  "&.Mui-selected": {
    color: "black",
    backgroundColor: "#e0e0e0",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#f5f5f5",
  },
  "&:hover": {
    backgroundColor: "#f5f5f5",
  },
}));

function AdminAccountSettings({ theme }) {
  const [user, setUser] = useState(null);
  const [practice, setPractice] = useState(null);
  const [vetData, setVetData] = useState(null);
  const [patientData, setPatientData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [alignment, setAlignment] = React.useState(
    "employees" || options[0].value
  );
  const token = localStorage.getItem("token");
  const adminId = practice?.id;
  const apiUrl = process.env.REACT_APP_API_URL || "";
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const casesPerVet = Math.round(
    practice?.total_appointments / practice?.total_veterinarians
  );
  const casesPerPatient = Math.round(
    practice?.total_appointments / practice?.total_pets
  );
  const hospitalCode = practice?.practice_code;
  const options = [
    { value: "employees", label: "Employees" },
    { value: "patients", label: "Patients" },
  ];
  const employeeDataPoints = [
    { value: practice?.total_veterinarians, label: "Total Veterinarians" },
    { value: practice?.premium_veterinarians, label: "Premium Subscribers" },
    { value: casesPerVet, label: "Average cases per veterinarian" },
  ];

  const patientDataPoints = [
    { value: practice?.total_pets, label: "Total Patients" },
    { value: practice?.total_appointments, label: "Total Appointments" },
    { value: casesPerPatient, label: "Average cases per Patient" },
  ];

  const employeeRows = vetData?.map((vet) => ({
    name: vet.first_name + " " + vet.last_name,
    email: vet.email,
    phone: vet.phone_number,
    specialty: vet.specialty,
    total_cases: vet.appointments?.length || 0,
    subscription_status: vet.subscription_status,
    actions: (
      <IconButton
        onClick={() => handleRemoveVet(vet.id)}
        aria-label={`Remove ${vet.first_name} ${vet.last_name}`}
        sx={{ color: "red" }}
      >
        <RemoveCircleOutlineIcon />
      </IconButton>
    ),
  }));

  const employeeColumns = [
    { field: "name", headerName: "Name" },
    { field: "email", headerName: "Email" },
    { field: "phone", headerName: "Phone" },
    { field: "specialty", headerName: "Specialty" },
    { field: "total_cases", headerName: "Total Cases" },
    { field: "subscription_status", headerName: "Subscription Status" },
    { field: "actions", headerName: "Remove" },
  ];

  const patientRows = patientData?.map((p) => ({
    name: p.name,
    age: p.age,
    breed: p.breed,
    sex: p.sex,
    weight: p.weight,
    total_cases: p.appointments?.length || 0,
  }));

  const patientColumns = [
    { field: "name", headerName: "Name" },
    { field: "age", headerName: "Age" },
    { field: "breed", headerName: "Breed" },
    { field: "sex", headerName: "Sex" },
    { field: "weight", headerName: "Weight" },
    { field: "total_cases", headerName: "Total Cases" },
  ];

  const handleAlignmentChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  useEffect(() => {
    fetchVetData();
    fetchPatientData();
  }, [user, practice]);

  const fetchUserData = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/check_session`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const userData = await response.json();
        setUser(userData);
        fetchPracticeData(userData.admin_id);
        console.log("userData", userData);
        // console.log('admin_id', userData.admin_id);
      } else {
        console.error("Failed to fetch user data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      setLoading(false);
    }
  };

  const fetchPracticeData = async (adminId) => {
    try {
      const response = await fetch(`${apiUrl}/api/v1/practice/${adminId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response.ok) {
        const practiceData = await response.json();
        console.log(practiceData);
        setPractice(practiceData);
      } else {
        console.error("Failed to fetch practice data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching practice data:", error);
      setLoading(false);
    }
  };

  const fetchVetData = async () => {
    try {
      // console.log(adminId)
      const response = await fetchVetDataApi(apiUrl, adminId, token);
      if (response.ok) {
        const incomingVetData = await response.json();
        // console.log(incomingVetData);
        setVetData(incomingVetData);
      } else {
        console.error("Failed to fetch vet data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching vet data:", error);
      setLoading(false);
    }
  };

  const fetchPatientData = async () => {
    try {
      console.log(adminId);
      const response = await fetchPatientDataApi(apiUrl, adminId, token);
      if (response.ok) {
        const incomingPatientData = await response.json();
        console.log(incomingPatientData);
        setPatientData(incomingPatientData);
      } else {
        console.error("Failed to fetch patient data");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching patient data:", error);
      setLoading(false);
    }
  };

  const handleRemoveVet = async (vetId) => {
    setError("");
    setSuccess("");
    try {
      const response = await fetch(
        `${apiUrl}/api/v1/remove_vet_from_practice/${vetId}`,
        {
          method: "PATCH",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.ok) {
        const data = await response.json();
        setSuccess(data.message);
        fetchPracticeData(user.admin_id);
      } else {
        const errorData = await response.json();
        setError(errorData.error);
      }
    } catch (error) {
      console.error("Error removing veterinarian:", error);
      setError("An error occurred while removing the veterinarian.");
    }
  };

  if (!practice) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mt: 5,
          minHeight: "100vh", // Ensures the parent Box takes up the full viewport height
        }}
      >
        <Box
          sx={{
            backgroundColor: "#BDE9FB",
            padding: isMobile ? 2 : 4,
            width: "75%",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "black",
              mb: isMobile ? 0.5 : 1,
              display: "inline-block",
              fontSize: isMobile ? "0.9rem" : "1.2rem",
            }}
          >
            Welcome to {practice?.practice_name}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
              justifyContent: "space-between",
              alignItems: isMobile ? "flex-start" : "center",
              mb: isMobile ? 0.5 : 1,
            }}
          >
            <Typography
              // variant={"h4"}
              sx={{
                color: "black",
                // mb: isMobile ? 0 : 1,
                display: "inline-block",
                textTransform: "capitalize",
                fontSize: "2rem",
                mr: 10,
              }}
            >
              Practice Dashboard
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={alignment}
              exclusive
              onChange={handleAlignmentChange}
              aria-label="Platform"
              sx={{ mb: 2 }}
            >
              {options.map((option) => (
                <StyledToggleButton key={option.value} value={option.value}>
                  {option.label}
                </StyledToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>
          <Box sx={{ minHeight: "100vh" }}>
            <DataOverview
              employeeDataPoints={employeeDataPoints}
              patientDataPoints={patientDataPoints}
              alignment={alignment}
            />
            <AdminDataGrid
              employeeRows={employeeRows}
              patientRows={patientRows}
              employeeColumns={employeeColumns}
              patientColumns={patientColumns}
              alignment={alignment}
              hospitalCode={hospitalCode}
            />
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AdminAccountSettings;
