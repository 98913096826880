import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
  Typography,
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useAuth0 } from "@auth0/auth0-react";
import StyledOverFlowMenu from "./StyledComponents/StyledOverFlowMenu";

function NavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { loginWithRedirect } = useAuth0();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        <ListItem
          onClick={() => loginWithRedirect()}
          sx={{ ...navLinkStyles, cursor: "pointer" }}
          button
        >
          <ListItemText primary="Login" />
        </ListItem>
        <ListItem
          component={NavLink}
          to="/vet-signup"
          sx={{ ...navLinkStyles, cursor: "pointer" }}
          button
        >
          <ListItemText primary="Vet Signup" />
        </ListItem>
        <ListItem
          component={NavLink}
          to="/admin-signup-check"
          sx={{ ...navLinkStyles, cursor: "pointer" }}
          button
        >
          <ListItemText primary="Admin Signup" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: "white", borderBottom: "1.5px solid black" }}
    >
      <Toolbar>
        <Link to="/">
          <img
            src="/ailovet-logo.png"
            alt="AiLoVet Logo"
            width={50}
            height={50}
            style={{ cursor: "pointer" }}
          />
        </Link>
        <Typography
          variant="h6"
          component={NavLink}
          to="/"
          sx={{
            ml: 2,
            flexGrow: 1,
            color: "#333",
            textDecoration: "none",
            fontWeight: "bold",
          }}
        >
          Home
        </Typography>
        {isMobile ? (
          <>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon sx={{ color: "#333" }} />
            </IconButton>
            <Drawer
              anchor="right"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{ keepMounted: true }}
            >
              {drawer}
            </Drawer>
          </>
        ) : (
          <Box sx={{ display: "flex", gap: "20px" }}>
            {/* <Typography
              onClick={() =>
                loginWithRedirect({
                  authorizationParams: { screen_hint: "signup" },
                })
              }
              sx={{ ...navLinkStyles, cursor: "pointer" }}
            >
              Signup
            </Typography> */}
            <Typography
              onClick={() => loginWithRedirect()}
              sx={{ ...navLinkStyles, cursor: "pointer" }}
            >
              Login
            </Typography>
            <Typography
              sx={{ ...navLinkStyles, cursor: "pointer", fontFamily: "arial" }}
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              disableElevation
              onClick={handleClick}
            // endIcon={<KeyboardArrowDownIcon />}
            >
              Signup
            </Typography>
            <StyledOverFlowMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem
                component={NavLink}
                to="/vet-signup"
                onClick={handleClose}
                sx={{ fontSize: "14px" }}
                disableRipple
              >
                Vet Signup
              </MenuItem>
              <MenuItem
                component={NavLink}
                to="/admin-signup-check"
                onClick={handleClose}
                sx={{ fontSize: "14px" }}
                disableRipple
              >
                Admin Signup
              </MenuItem>
            </StyledOverFlowMenu>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
}

const navLinkStyles = {
  color: "#333",
  textDecoration: "none",
  fontSize: "14px",
  fontWeight: 500,
  "&:hover": { color: "#00856a" },
};

export default NavBar;
