import { useNavigate } from 'react-router-dom';
import { Container, Typography, Button, Box, Stack, Grid, Paper, Avatar } from '@mui/material';
import { PetsRounded, AccessTimeRounded, VerifiedUserRounded, AutoStoriesRounded, BusinessCenterRounded, EventAvailableRounded, StarRounded, TouchAppRounded, FolderSpecialRounded } from '@mui/icons-material';
import { motion } from 'framer-motion';
import React from 'react';

export default function Home() {
  const navigate = useNavigate();

  return (
    <Box>
      {/* Hero Section with distinct background */}
      <Box sx={{
        background: 'linear-gradient(135deg, #001f3f 0%, #003366 100%)',
        position: 'relative',
        overflow: 'visible',
        minHeight: '75vh',
        display: 'flex',
        alignItems: 'center',
        paddingBottom: '100px',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 50% 50%, rgba(15, 190, 183, 0.2) 0%, rgba(15, 190, 183, 0) 50%)',
          pointerEvents: 'none',
        }
      }}>
        <Container maxWidth="lg">
          <Stack direction={{ xs: 'column', md: 'row' }} spacing={4} sx={{
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
          }}>
            <Box
              flex={1}
              component={motion.div}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              sx={{
                pt: { xs: 4, md: 0 },
                maxWidth: '800px',
                margin: '0 auto'
              }}
            >
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  color: '#ffffff',
                  fontSize: { xs: '3rem', md: '4.5rem' },
                  lineHeight: 1.2,
                }}
              >
                Smart Veterinary Practice Management
              </Typography>
              <Typography variant="h5" sx={{ mb: 4, color: 'rgba(255, 255, 255, 0.9)' }}>
                Streamline your veterinary practice with AI-powered documentation, collaborative tools, and intelligent medical record creation.
              </Typography>
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/vet-signup')}
                sx={{
                  bgcolor: '#0FBEB7',
                  color: "white",
                  '&:hover': { bgcolor: '#0ca69f' },
                  boxShadow: "0 4px 14px 0 rgba(15, 190, 183, 0.39)",
                  px: 4,
                  py: 1.25
                }}
              >
                Get Started
              </Button>
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Features section */}
      <Box sx={{ background: '#fff' }}>
        <Container maxWidth="lg" sx={{ transform: 'translateY(-80px)' }}>
          <Stack spacing={6}>
            {/* Features Section */}
            <Box>
              <Stack
                direction={{ xs: 'column', md: 'row' }}
                spacing={4}
                sx={{ width: '100%' }}
              >
                {features.map((feature, index) => (
                  <Paper
                    key={index}
                    component={motion.div}
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8, delay: index * 0.2 }}
                    elevation={0}
                    sx={{
                      p: 4,
                      flex: 1,
                      background: '#ffffff',
                      borderRadius: 2,
                      transition: 'all 0.3s ease-in-out',
                      boxShadow: '0 4px 14px 0 rgba(15, 190, 183, 0.39)',
                      '&:hover': {
                        transform: 'translateY(-8px) scale(1.02)',
                        boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                        background: '#ffffff',
                      }
                    }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      {feature.icon}
                      <Typography variant="h6" sx={{ ml: 1, color: '#0FBEB7' }}>
                        {feature.title}
                      </Typography>
                    </Box>
                    <Typography color="text.secondary">
                      {feature.description}
                    </Typography>
                  </Paper>
                ))}
              </Stack>
            </Box>

            {/* Audience-Specific Benefits */}
            <Box sx={{ mt: 8 }}>
              {audiences.map((audience, index) => (
                <Box
                  key={audience.title}
                  component={motion.div}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.8 }}
                  sx={{
                    mb: 8,
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -40,
                      left: '10%',
                      right: '10%',
                      height: '1px',
                      background: 'linear-gradient(90deg, transparent, rgba(15, 190, 183, 0.3), transparent)',
                      display: index === audiences.length - 1 ? 'none' : 'block'
                    }
                  }}
                >
                  <Box sx={{
                    textAlign: 'center',
                    mb: 6,
                    position: 'relative',
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: -15,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: '60px',
                      height: '4px',
                      background: 'linear-gradient(90deg, #1976d2, #0FBEB7)',
                      borderRadius: '2px'
                    }
                  }}>
                    <Typography
                      variant="h2"
                      sx={{
                        fontWeight: 700,
                        background: 'linear-gradient(45deg, #1976d2, #0FBEB7)',
                        backgroundClip: 'text',
                        WebkitBackgroundClip: 'text',
                        color: 'transparent',
                        mb: 2
                      }}
                    >
                      {audience.title}
                    </Typography>
                  </Box>

                  <Grid container spacing={4} sx={{ mt: 4 }}>
                    {audience.benefits.map((benefit, benefitIndex) => (
                      <Grid item xs={12} md={4} key={benefitIndex}>
                        <Box
                          component={motion.div}
                          initial={{ opacity: 0, y: 20 }}
                          whileInView={{ opacity: 1, y: 0 }}
                          viewport={{ once: true }}
                          transition={{ duration: 0.5, delay: benefitIndex * 0.2 }}
                          sx={{
                            position: 'relative',
                            background: 'linear-gradient(135deg, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0.05) 100%)',
                            backdropFilter: 'blur(10px)',
                            borderRadius: '20px',
                            p: 4,
                            height: '100%',
                            transition: 'all 0.3s ease-in-out',
                            border: '1px solid rgba(255,255,255,0.2)',
                            overflow: 'hidden',
                            '&:hover': {
                              transform: 'translateY(-8px)',
                              '& .benefit-icon': {
                                transform: 'scale(1.1) rotate(10deg)',
                              },
                              '& .benefit-gradient': {
                                opacity: 0.8,
                              }
                            }
                          }}
                        >
                          <Box
                            className="benefit-gradient"
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              right: 0,
                              bottom: 0,
                              background: `linear-gradient(135deg, rgba(15, 190, 183, 0.05) 0%, rgba(25, 118, 210, 0.05) 100%)`,
                              opacity: 0.5,
                              transition: 'opacity 0.3s ease-in-out',
                            }}
                          />
                          <Box
                            className="benefit-icon"
                            sx={{
                              background: 'linear-gradient(135deg, #0FBEB7 0%, #1976d2 100%)',
                              borderRadius: '16px',
                              p: 2,
                              display: 'inline-flex',
                              mb: 3,
                              transition: 'all 0.3s ease-in-out',
                              boxShadow: '0 10px 20px rgba(15, 190, 183, 0.2)',
                            }}
                          >
                            {React.cloneElement(benefit.icon, {
                              sx: { fontSize: 32, color: '#fff' }
                            })}
                          </Box>
                          <Typography
                            variant="h5"
                            sx={{
                              fontWeight: 600,
                              mb: 2,
                              background: 'linear-gradient(135deg, #0FBEB7 0%, #1976d2 100%)',
                              backgroundClip: 'text',
                              WebkitBackgroundClip: 'text',
                              color: 'transparent',
                            }}
                          >
                            {benefit.title}
                          </Typography>
                          <Typography
                            sx={{
                              color: 'text.secondary',
                              lineHeight: 1.7,
                              position: 'relative',
                              zIndex: 1
                            }}
                          >
                            {benefit.description}
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>

                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 6
                  }}>
                    <Button
                      variant="contained"
                      size="large"
                      onClick={() => navigate(audience.buttonLink)}
                      sx={{
                        background: 'linear-gradient(135deg, #0FBEB7 0%, #1976d2 100%)',
                        px: 6,
                        py: 1.5,
                        borderRadius: '30px',
                        fontSize: '1.1rem',
                        color: "white",
                        textTransform: 'none',
                        boxShadow: '0 10px 20px rgba(15, 190, 183, 0.2)',
                        '&:hover': {
                          background: 'linear-gradient(135deg, #0ca69f 0%, #1565c0 100%)',
                          transform: 'translateY(-2px)',
                          boxShadow: '0 15px 30px rgba(15, 190, 183, 0.3)',
                        }
                      }}
                    >
                      {audience.buttonText}
                    </Button>
                  </Box>
                </Box>
              ))}
            </Box>
          </Stack>
        </Container>
      </Box>

      {/* Testimonials Section */}
      <Box sx={{ py: 8, background: '#f8f9fa' }}>
        <Container maxWidth="lg">
          <Typography
            variant="h2"
            component="h2"
            sx={{
              textAlign: 'center',
              mb: 6,
              background: 'linear-gradient(45deg, #1976d2, #0FBEB7)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              color: 'transparent',
              fontWeight: 600,
            }}
          >
            What Our Users Say
          </Typography>
          <Grid container spacing={4}>
            {testimonials.map((testimonial, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Box
                  component={motion.div}
                  initial={{ opacity: 0, y: 50 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      p: 4,
                      height: '100%',
                      background: 'white',
                      borderRadius: 2,
                      position: 'relative',
                      transition: 'all 0.3s ease-in-out',
                      '&:hover': {
                        transform: 'translateY(-8px)',
                        boxShadow: '0 20px 40px rgba(0,0,0,0.1)',
                      },
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 20,
                        left: 20,
                        fontSize: '4rem',
                        color: 'rgba(15, 190, 183, 0.2)',
                        fontFamily: 'serif',
                        lineHeight: 1,
                      }
                    }}
                  >
                    <Box sx={{ pl: 3 }}>
                      <Typography
                        variant="body1"
                        sx={{
                          mb: 3,
                          color: 'text.secondary',
                          fontStyle: 'italic',
                          minHeight: '80px'
                        }}
                      >
                        "{testimonial.quote}"
                      </Typography>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Avatar
                          src={testimonial.avatar}
                          sx={{
                            width: 56,
                            height: 56,
                            mr: 2,
                            border: '2px solid #0FBEB7'
                          }}
                        />
                        <Box>
                          <Typography variant="subtitle1" sx={{ fontWeight: 600, color: '#0FBEB7' }}>
                            {testimonial.name}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            {testimonial.role}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Paper>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

const audiences = [
  {
    title: "Practice Features",
    benefits: [
      {
        title: "AI-Powered Documentation",
        description: "Record appointments with our voice dictation system and receive instant AI-generated summaries, saving valuable time during consultations.",
        icon: <AutoStoriesRounded sx={{ color: '#0FBEB7', fontSize: 32 }} />,
      },
      {
        title: "Intelligent Differential Generation",
        description: "Get AI-suggested differential diagnoses based on consultation summaries, helping validate your clinical reasoning and ensure comprehensive care.",
        icon: <BusinessCenterRounded sx={{ color: '#0FBEB7', fontSize: 32 }} />,
      },
      {
        title: "Collaborative Care",
        description: "Enable multiple team members to contribute to appointments, share insights, and maintain consistent care standards across your practice.",
        icon: <EventAvailableRounded sx={{ color: '#0FBEB7', fontSize: 32 }} />,
      },
    ],
    buttonText: "Start Your Practice Trial",
    buttonLink: "/vet-signup"
  }
];

const features = [

  {
    title: 'Smart Medical Records',
    description: 'Automatically generate complete records from recorded exam conversations and selected differentials.',
    icon: <AutoStoriesRounded sx={{ color: '#0FBEB7', fontSize: 32 }} />,
  },
  {
    title: 'One-Click Treatment',
    description: 'Integrate intelligent exam records with expert veterinary insights to deliver the best treatment plans for pets.',
    icon: <VerifiedUserRounded sx={{ color: '#0FBEB7', fontSize: 32 }} />,
  },
  {
    title: 'Efficient Workflow',
    description: 'Save time with AI-powered documentation and automated record generation, allowing more focus on patient care.',
    icon: <AccessTimeRounded sx={{ color: '#0FBEB7', fontSize: 32 }} />,
  },
];

const testimonials = [
  {
    quote: "The AI-powered dictation and automatic summary generation has revolutionized our workflow. We're saving hours each day on documentation while maintaining detailed records.",
    name: "Dr. Sarah Mitchell",
    role: "Practice Owner",
    avatar: "/avatars/sarah.jpg"
  },
  {
    quote: "The differential diagnosis suggestions are incredibly helpful, especially for complex cases. It's like having an experienced colleague double-checking your work.",
    name: "Dr. Michael Rodriguez",
    role: "Associate Veterinarian",
    avatar: "/avatars/michael.jpg"
  },
  {
    quote: "Being able to collaborate with my colleagues on cases in real-time has improved our patient care significantly. The automated medical records are clear and professional.",
    name: "Dr. Emily Chen",
    role: "Senior Veterinarian",
    avatar: "/avatars/emily.jpg"
  }
];

const navLinkStyles = {
  color: "#333",
  textDecoration: "none !important",
  fontSize: "14px",
  fontWeight: 500,
  "&:hover": { color: "#0FBEB7" },
};
